import React, { useState, useEffect } from 'react'; 
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom' 
import { getCircular } from "../Service/Api";
const Syllabus = () => {
    useEffect(() => {
        document.title = 'Syllabus - St. Joseph’s High School, Dabwali ';  
    }, []); 
    
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [yearRanges, setYearRanges] = useState([]); 
  
    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear}`;
      setSelectedYear(Year);
      console.log("get current Year", Year);
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        const circularData = await getCircular();
        setData(circularData);
        const circularYears = extractYears(circularData);
        setYearRanges(circularYears);
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      if (data.length === 0) {
        return;
      }
    
      let filteredData = data.filter((item) => item.category === "Syllabus");
      let currentFilteredData = filterDataByYear(filteredData, selectedYear);
    
      // If no data for the selected year, fallback to previous year with data
      if (currentFilteredData.length === 0) {
        const availableYears = yearRanges;
        for (let i = 0; i < availableYears.length; i++) {
          const fallbackYear = availableYears[i];
          currentFilteredData = filterDataByYear(filteredData, fallbackYear);
          if (currentFilteredData.length > 0) {
            setSelectedYear(fallbackYear); // Update selected year to the fallback year
            break;
          }
        }
      }
    
      // Sort the currentFilteredData by date in descending order (latest on top)
      currentFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
    
      setFilteredData(currentFilteredData);
    }, [selectedYear, data, yearRanges]);
    
    
  
    const filterDataByYear = (data, year) => {
      if (year === "All") return data;
      return data.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const itemYear = new Date(item.date).getFullYear();
          return parseInt(year) === itemYear;
        }
        return false;
      });
    };
  
    const extractYears = (data) => {
      const years = data
        .filter((item) => item.category === "Syllabus")
        .map((item) => {
          if (item.date && item.date.trim() !== "") {
            return new Date(item.date).getFullYear().toString();
          }
          return null;
        })
        .filter((year, index, self) => year && self.indexOf(year) === index)
        .sort((a, b) => b - a); // Sort years in descending order
      return years;
    };
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Download</li>
                 <li>  Syllabus</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  Syllabus </h1>
                    </div>
                </div>
                <div className="row"> 
                    {filterData.length > 0 ? (
                        filterData.map((item, index) => (
                            <div className="col-lg-4" key={index}> 
                            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} target="_blank">
                                <div class="sample-blk">
                                  <div>
                                       <p> {item.title}</p>
                                      <span>{item.date}</span>
                                  </div> 
                               <img alt=" St. Joseph’s High School, Dabwali" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/pdf.png" class="img-fluid"/> </div> 
                               </Link>
                        </div>
                        ))
                      ) : (
                        <div className="col-lg-4"> 
                        <a href="#" target="_blank"> 
                            <div class="sample-blk">
                              <div>
                                   <p> Title</p>
                                  <span>DD.MM.YY</span>
                              </div> 
                           <img alt=" St. Joseph’s High School, Dabwali" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/pdf.png" class="img-fluid"/> </div> 
                        </a>  
                    </div>
                      )}
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default Syllabus

