import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Home from'./Pages/Home'
import About from'./Pages/About'
import AboutSociety from'./Pages/AboutSociety'
import OurStaff from'./Pages/OurStaff'
import Patron from'./Pages/Patron'
import SchoolCabinet from'./Pages/SchoolCabinet'
import SchoolInfrastructure from'./Pages/SchoolInfrastructure'
import Admission from'./Pages/Admission'
import Login from'./Pages/Login'
import HomeWork from'./Pages/HomeWork'
import Syllabus from'./Pages/Syllabus'
import Circular from'./Pages/Circular'
import Contactus from'./Pages/Contactus'
import Events from'./Pages/Events'
import Gallery from'./Pages/Gallery'
import Activites from'./Pages/Activites'
import News from'./Pages/News'
import SchoolNotice from'./Pages/SchoolNotice'
import SubGallery from'./Pages/SubGallery'
import SubActivites from'./Pages/SubActivites'
import Toppers from'./Pages/Toppers'
import VideoGallery from'./Pages/VideoGallery'
import ErpSoftware from'./Pages/ErpSoftware'
import Pphelp from'./Pages/Pphelp'
import Tc from'./Pages/Tc'
import ManagerMessage from'./Pages/ManagerMessage'
import PrincipalMessage from'./Pages/PrincipalMessage'
import KidsCorner from'./Pages/KidsCorner' 
import KidsCornerSubGallery from'./Pages/KidsCornerSubGallery'
import HomeKiosk from'./Pages/HomeKiosk'
function App() { 
  return (
      <>
      <BrowserRouter> 
      <Routes>
       <Route path="/" element={<Home/>} /> 
        <Route path="/About" element={<About/>} /> 
        <Route path="/AboutSociety" element={<AboutSociety/>} /> 
        <Route path="/OurStaff" element={<OurStaff/>} /> 
        <Route path="/Patron" element={<Patron/>} /> 
        <Route path="/SchoolCabinet" element={<SchoolCabinet/>} /> 
        <Route path="/SchoolInfrastructure" element={<SchoolInfrastructure/>} /> 
        <Route path="/Admission" element={<Admission/>} />
        <Route path="/Login" element={<Login/>} />
        <Route path="/HomeWork" element={<HomeWork/>} />
         <Route path="/Syllabus" element={<Syllabus/>} />
         <Route path="/Circular" element={<Circular/>} />
         <Route path="/Contactus" element={<Contactus/>} />
         <Route path="/Events" element={<Events/>} />
         <Route path="/Gallery" element={<Gallery/>} />
         <Route path="/News" element={<News/>} />
         <Route path="/SubGallery" element={<SubGallery/>} />
         <Route path="/Activites" element={<Activites/>} />
         <Route path="/SchoolNotice" element={<SchoolNotice/>} />
         <Route path="/SubActivites" element={<SubActivites/>} />
         <Route path="/Toppers" element={<Toppers/>} />
         <Route path="/VideoGallery" element={<VideoGallery/>} />
         <Route path="/ErpSoftware" element={<ErpSoftware/>} />
         <Route path="/Pphelp" element={<Pphelp/>} />
         <Route path="/Tc" element={<Tc/>} />
         <Route path="/ManagerMessage" element={<ManagerMessage/>} />
         <Route path="/PrincipalMessage" element={<PrincipalMessage/>} />
          <Route path="/KidsCorner" element={<KidsCorner/>} />
          <Route path="/KidsCornerSubGallery" element={<KidsCornerSubGallery/>} />
          <Route path="/HomeKiosk" element={<HomeKiosk/>} />
      </Routes> 
     </BrowserRouter>
      </>
  )
}

export default App;
