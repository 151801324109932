import React, { useState, useEffect } from 'react'; 
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import { form1 } from "../Service/Api";
import { getHeader } from "../config";

const Login = () => {
    useEffect(() => {
        document.title = 'Login - St. Joseph’s High School, Dabwali ';  
    }, []);  
    
    const [formData, setFormData] = useState({
      mobileno: "", 
      password: "", 
  });

  const [errors, setErrors] = useState({});
  
  const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value
      });
  };

  const validate = () => {
      const newErrors = {};

      if (!formData.mobileno) {
          newErrors.mobileno = "Mobile number is required";
      } else if (!/^[0-9]{10}$/.test(formData.mobileno)) {
          newErrors.mobileno = "Mobile number must be 10 digits";
      }
 

      if (!formData.password) {
          newErrors.password = "Password is required";
      } else if (formData.password.length < 6) {
          newErrors.password = "Password must be at least 6 characters long";
      }
 

      return newErrors;
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = validate();

      if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
      }

      const type = 'Admission';
      console.log('Submitting form with type:', type);

      try {
          const { header } = getHeader();
          const response = await form1(formData, header, type);
          console.log('Form submitted successfully', response);
          document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
          // Reset the form data state
          setFormData({
              mobileno: "",
              firstName: "",
              password: "",
              confmpassword: ""
          });
      } catch (error) {
          console.error('Error submitting form:', error);
      }
  };

  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                 <li>  Login</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  Login  </h1>
                    </div>
                </div> 
                <form className="formsec" onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Mobile No.</label>
                        <input
                            type="text"
                            name="mobileno"
                            value={formData.mobileno}
                            onChange={handleChange}
                            className="form-control"
                        />
                        {errors.mobileno && <p className="error">{errors.mobileno}</p>}
                    </div>
                </div> 
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={formData.password}
                            name="password"
                            onChange={handleChange}
                            className="form-control"
                        />
                        {errors.password && <p className="error">{errors.password}</p>}
                    </div>
                </div>
                
                 <div className="col-lg-6 d-flex flex-wrap">
                <input type="submit" value="Login"/>
                <Link className="frmbtn" to="/Admission">New Registration</Link>
                </div>
                
                <div className="col-lg-12 col-md-12">
                    <div className="primary-alert"></div>
                </div>
                </div>
                </form>
                <div className="row">
                    <div className="col-lg-12"> 
                        <h3>Documents to be attached along with the Printed Copy of registration Form.</h3>
                        <ul className="list">
                                <li>Photo : Passport size photo of the child and family must be attached to the Form.</li>
                                <li>Birth Certificate: ORIGINAL BIRTH CERTIFICATE along with attested photocopy of the certificate should be attached to the printed registration form. Without the       attestation, the form will not be accepted. The ORIGINAL copy will be returned after verification.</li>
                                <li>Residential proof: Attested copy of Ration card/Last Electricity bill (If tanent , Submit affidavit of residence proof/Family ID)</li>
                                <li>Sibling : Attested copy of the latest fee booklet of the same year.</li>
                                <li>Religion : If Christian, Attested photocopy of Baptism certificate.</li>
                                <li>Kindly bring Registration fees of 300/- along with the documents in the School Clerical office.</li>
                                <li>Incomplete Registration Form shall be rejected.</li>
                                <li>Bring two prints of the Acknowledgement Receipt at time of verification.</li>
                        </ul>
                    </div>
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default Login

