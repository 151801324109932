import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://entab.online/SJSMHR" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="SJHSMD"/></Link>
      </div>
      </>
        
  )
}

export default Campuscare
