
import OwlCarousel from 'react-owl-carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Assets/kioskApp.css';
import Teacherbirthdays from '../Component/Teacherbirthdays'
import Acheivers from '../Component/Acheiver'
import Birthday from '../Component/StudentBirthdays';
import KioskActivities from '../Component/KioskActivities';
import KioskNews from '../Component/KioskNews';

const HomeKiosk = () => {
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        autoplayTimeout: 900000,
        items: 1,
    };
    return (
        <>
            <div className="slidescreen kioskApp">
                <div className="demo">
                    <div className="item">
                        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
                            {/* <li className="item">
                                <div class="slide1">
                                    <div class="slide_blk">
                                        <div class="logo-kiosk">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="St Joseph School, Mandi" />
                                        </div>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-xxl-6 col-md-6'>
                                                    <div class="school_img">
                                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" />
                                                    </div>
                                                </div>

                                                <div className='col-xxl-6 col-md-6'>
                                                    <div className='screen-title'>
                                                        <div class="title d-flex"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/title-img1.png" alt="St Joseph School, Mandi" />
                                                            <h3>Welcome To <br></br><span>St. Joseph's High School, Mandi</span></h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/title-img2.png" alt="St Joseph School, Mandi" />
                                                        </div>
                                                        <div class="content">
                                                            <p>St. Joseph’s High School, Dabwali is run by the registered Society of Pilar, Punjab- Haryana, a branch of the Society of Pilar, Goa.The school is run by the religious minority in fulfillment of its constitution right under article 30 (1).This school is affiliated to the Council for the Indian School Certificate Examination, New Delhi. St. Joseph’s High School was opened in 1970 to cater to the pressing educational needs of Mandi Dabwali and the surroundings villages. The school aims at all round development of the child, moral along with the hope of drawing out the best in each, so as to enable its pupils to blossom into gems of persons and capable citizens of our country.</p>
                                                            <p>The school caters to the students up to Std-X. The medium of instruction is English. Hindi and Punjabi are also taught as additional languages. Having gauged the usefulness of computers, smart classes, the school gives stress to training in Computer Science.An atmosphere of warmth and caring is provided by capable teachers to each child so that he/she blossoms to their full stature. </p>
                                                        </div>
                                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/bandstrip.png" alt="St Joseph School, Mandi" class="bandstrip" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </li> */}
                            <li className="item">
                                <div class="topper-blk wrapper">
                                    <div class="container-fluid scrollbox">
                                        <div class="logo-kiosk">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="St Joseph School, Mandi" />
                                        </div>
                                        <div className='row'>
                                        <div className='col-xxl-12 col-md-12'>
                                                <div class="title-bar">
                                                    <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/trophy-2.png" alt="St Joseph School, Mandi" /> School <b>Activities</b></h3>
                                                </div>

                                                <KioskActivities />
                                            </div> 
                                       
                                        </div>
 
                                    </div>
                                </div>
                            </li>
                            <li className="item">
                                <div class="topper-blk wrapper">
                                    <div class="container-fluid scrollbox">
                                        <div class="logo-kiosk">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="St Joseph School, Mandi" />
                                        </div>
                                        <div className='row'>
                                         
                                            <div className='col-xxl-12 col-md-12'>
                                                <div class="title-bar">
                                                    <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/trophy-1.png" alt="St Joseph School, Mandi" /> School <b>Achievements</b></h3>
                                                </div>
                                                <Acheivers />
                                            </div>
                                       
                                        </div>
 
                                    </div>
                                </div>
                            </li>
                           
                            <li>
                                <div className='gallery-section'>
                                    <div className='gallery-shade'>
                                    <div className='container-fluid'>
                                    <div class="row">
                                        <div className='col-lg-12'>
                                        <div class="gal-logo">
                                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="St Joseph School, Mandi" />
                                                </div>
                                        </div>
                                        <div class="col-lg-6 col-xl-6 col-xxl-6"> 
                                                <div class="birthday-blk">
                                                    {/* <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/birthday.gif" class="bth-gif" alt="St Joseph School, Mandi" /> */}
                                                    
                                                                <div class="birthdays-blk">
                                                                    <div class="title-bar">
                                                                        <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/birth.png" alt="St Joseph School, Mandi" /> Student's <b>Birthday</b></h3>
                                                                    </div>

                                                                    <Birthday />


                                                                </div> 
 
                                                                <div class="birthdays-blk">
                                                                    <div class="title-bar">
                                                                        <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/birth.png" alt="St Joseph School, Mandi" /> Teacher's <b>Birthday</b></h3>
                                                                    </div>

                                                                    <Teacherbirthdays />
                                                                </div>  
                                                    <div class="lines">
                                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/lines.png" alt="St Joseph School, Mandi" />
                                                    </div>

                                                </div> 
                                        </div>
                                        <div class="col-lg-6 col-xl-6 col-xxl-6"> 
                                               
                                                <div class="gallery-blk-kiosk">
                                                    <div class="title-bar">
                                                        <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/newspaper.png" alt="St Joseph School, Mandi" /> Latest  <b>News</b></h3>
                                                    </div>

                                                </div>
                                                <div className='news'>
                                                    <marquee scrollamount="2" scrolldelay="30" direction="up" align="left">
                                                        <KioskNews />
                                                    </marquee>
                                                </div>
                                                <div class="lines">
                                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/lines.png" alt="St Joseph School, Mandi" />
                                                </div> 
                                        </div>
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/plant.png" class="img-plant" alt="St Joseph School, Mandi" />
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/plant.png" class="img-plant-left" alt="St Joseph School, Mandi" />
                                    </div>
                                </div>
                                    </div>
                                </div>
                               
                            </li>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeKiosk;