import React, { useEffect, useState } from "react";
import { getGalleryInner } from "../Service/Api";
import { Link } from 'react-router-dom' 
import Slider from "react-slick";
const Activities = () => {
  const [data, setData] = useState([]);
 const [loading, setLoading] = useState(true); 
   useEffect(() => {
   const fetchData = async () => {
        try{
          const galleryData = await getGalleryInner();
          setData(galleryData);
        }catch (error) {
          console.log("Error fetching gallery data:",error)
        }finally{
          setLoading(false)
        }
     
     };
   fetchData();
   }, []);

   const emptyData = [
    { title: "Achivement Title", img:"https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" },   
    { title: "Achivement Title", img:"https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" },   
    { title: "Achivement Title", img:"https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" },
    { title: "Achivement Title", img:"https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" } 
    
    
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      }
    ]
  };
  const filteredData = data.filter(item => item.category === "Achievements");

  return (
    <>
       
        <Slider {...settings}>
          {(filteredData.length > 0 ? filteredData : emptyData).map((item, index) => ( 
             <div className="item" key={index}>
               <div className="activites">
                 <div className="activitesbox">
                     <img
                    src={item.attachments ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg"}
                    alt={item.title} // Use item title as alt text for better accessibility
                    className="img-fluid"
                  />
                   <Link to="/" className="gallery_button">Read More</Link>
                   </div>
                   <div className="gallerydesc">
                     
                     <h6>{item.title}</h6>
                   </div>
                  
               </div>
             </div>
          ))}
        </Slider>
     
    </>
  )
}

export default Activities