import React, {useEffect } from 'react';
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const SchoolInfrastructure = () => {
    useEffect(() => {
        document.title = 'School Infrastructure - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>  School Infrastructure</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  School Infrastructure  </h1>
                    </div>
                </div>
                <div className="row">
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra1.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra1.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Library</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra2.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra2.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Dance + Music</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra3.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra3.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Dance + Music</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra4.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra4.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Smart Classroom</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra5.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra5.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Computer Lab</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra6.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra6.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Biology Lab</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra7.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra7.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Physics Lab</div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra8.jpg"><img className="img-fluid infraimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/infra8.jpg" alt="St. Joseph’s High School, Dabwali"/> 
    </a> <div className="infrastructuredesc">Chemistry Lab</div>
    </div>
 </div> 
            </div>
         </div>
         <Footer />
     </>
  )
}

export default SchoolInfrastructure

