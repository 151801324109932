import React, {useEffect } from 'react';
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const AboutSociety = () => {
    useEffect(() => {
        document.title = 'About Society - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>  About Society</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  About Society  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <p>The Society of the Missionaries of St. Francis Xavier, Pilar commonly known as the Society of Pilar, was founded on 26th September, 1887 by Fr. Bento Martins who was an ordinary priest with extraordinary vision. Inspired by the zeal to make Jesus Christ known to the places brought under the erstwhile Portuguese Rule, he formed a group of like minded priests and named the Society after the great Missionary, Francis Xavier. </p>

                    <p>In 1891, they transferred their headquarters to the Monastry of Our Lady of Pilar. It is a beautiful hillock situated 12 kms. South East of Panjim, the capital of Goa. Thus the group came to be known as the Pilar Fathers. On 2nd July, 1939, Fr. Remedios Gomes along with Five other priests and two laymen, gave the Society the much needed vitality and stability by revamping the Constitution. They would now commit their efforts mainly to share the Gospel of Jesus to those who did not know him.</p>
                    
                    <p>The Pilat Society is an indigenous Society founded with the aim of sharing the Gospel of Justice, freedom and love with our countrymen.</p>
                    </div>
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default AboutSociety

