import React, { useState, useEffect } from 'react'; 
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom' 
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => { 
    useEffect(() => {
        document.title = 'Principal  Message - St. Joseph’s High School, Dabwali';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
        {
          id: 1,
          message: "Welcome to the website of St. Joseph’s High School, Mandi Dabwali  As you navigate your way through the site discovering what we have to offer, you will find answers to the many preliminary questions you may have about our school, our academic programme and student life. As a principal of the school, I feel honored and privileged to be part of an educational institution where every stakeholder is a learner and every day is an opportunity to learn and discover. We look at ourselves as a community of learners, where everyone learns including our teachers, parents and staff. I believe in upholding high standards with an absolute commitment to strive to understand and improve the educational process, using team strategies, while wholly centering on student achievement.                          ",
          name: "Fr. Jerry Paul Lobo",
          category: "Principal",
          attachments:""
        }
      ];
      const filteredData = data.filter(div => div.category === "Principal");
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Principal's Message</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Principal's Message </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    {(filteredData.length > 0 ? filteredData : emptyData).map((item, index) => (
                        <div className="message-container" key={item.id || index}>
                                 <div className="clearfix">
                             <img
                  src={item.attachments ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/principal.jpg"}
                  alt="Message"
                   className="col-md-4 float-md-end  left-img img-fluid"/>
                                        
                                         <div className="mesdeskimg"> 
                                            <h3> {item.name}</h3>
                                            <h5> {item.category}</h5>
                                         </div>
                                         <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
                     
                                         </div>
                        </div>
                
                
                     
                      ))}
                    </div>
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default PrincipalMessage

