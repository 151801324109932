import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/SJHSMD`);
    console.log("Highlight",data)
    return data;
} 
 
export const getNews = async() => { 
    const { data } = await axios.get(API_URL + `/api/news/SJHSMD`);
    console.log("news:",data)
    return data;
} 
export const getNewsInner = async() => { 
    const { data } = await axios.get(API_URL + `/api/news/all/SJHSMD`);
    console.log("newsInner:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/SJHSMD`);
    console.log("event", data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/SJHSMD`);
    console.log(data)
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/SJHSMD`);
    console.log("gallery",data)
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/SJHSMD`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/SJHSMD`,getHeader());
    console.log("popup",data)
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/SJHSMD`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/SJHSMD`,getHeader());
    console.log("online registration Data", data)
    return data;
} 


export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/SJHSMD`, getHeader());
   console.log(data)
    return data;
} 
 
export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/All/SJHSMD`);
      console.log(data)
    return data;
} 
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/SJHSMD`,{name,admission},getHeader());
    return data;
}
  
export const form1 = async (formData, header, type) => {
    try {
        const { data } = await axios.post(`${API_URL}/api/form/SJHSMD/${type}`, formData, header);
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data); 
        }
        throw error;
    }
  };
  export const academicCalender = async() => {
    const { data } = await axios.get(API_URL + `/api/academicCalender/all/SJHSMD`, getHeader());
    console.log("academic calender", data)
    return data;
}

export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/SJHSMD`, getHeader());
    console.log(data)
    return data;
} 


export const getBirthdays = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/SJHSMD`);
    console.log(data)
     return data;
} 