import React, { useState, useEffect } from 'react'; 
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom' 
import {getPrincipalMessage} from'../Service/Api'
const ManagerMessage = () => { 
    useEffect(() => {
        document.title = 'Manager Message - St. Joseph’s High School, Dabwali';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
        {
          id: 1,
          message: "Welcome to St. Joseph’s High, Mandi Dabwali, a community of learners dedicated to academic excellence, personal growth, and social responsibility. Education is one of the blessings for students. It is the most powerful weapon which can change the entire world. It makes the person powerful and confident. As the manager of this esteemed institution, I am proud to lead a team of passionate educators and support staff who strive to provide a nurturing and inclusive environment for our students. Our mission is to empower and inspire students to become visionary, creative thinkers, equipped with the skills and knowledge to succeed in an ever-changing world.",
          name: "Fr. John Britto",
          category: "Manager",
          attachments:""
        }
      ];
      const filteredData = data.filter(div => div.category === "Manager");
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Manager's Message</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Manager's Message </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    {(filteredData.length > 0 ? filteredData : emptyData).map((item, index) => (
                        <div className="message-container" key={item.id || index}>
                                 <div className="clearfix">
                             <img
                  src={item.attachments ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/manager.jpg"}
                  alt="Message"
                   className="col-md-4 float-md-end  left-img img-fluid"/>
                                        
                                         <div className="mesdeskimg"> 
                                            <h3> {item.name}</h3>
                                            <h5> {item.category}</h5>
                                         </div>
                                         <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
                     
                                         </div>
                        </div>
                
                
                     
                      ))}
                    </div>
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default ManagerMessage

