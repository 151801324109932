import { useEffect, useState } from 'react';
import { getGalleryInner } from '../Service/Api';
import { Carousel } from 'react-bootstrap';

const KioskActivities = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [chunkedData, setChunkedData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getGalleryInner();
                const getResponse = response.filter(item => item.attachments && item.attachments.length > 0);
                const getAttachments = getResponse.flatMap(item => item.attachments); // Flatten attachments array
                setData(getAttachments);
                console.log("received attachments", getAttachments);
            } catch (error) {
                console.error('Error fetching data:', error);
                setData([]);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        const chunkArray = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        };

        if (filteredData.length > 0) {
            const chunked = chunkArray(filteredData, 8); // Change 2 to desired chunk size
            setChunkedData(chunked);
        } else {
            setChunkedData([]);
        }
    }, [filteredData]);

    return (
        <>
            <Carousel className="carousel slide kiosk-gallery-blk">
                {chunkedData.length > 0 ? (
                    chunkedData.map((group, index) => (
                        <Carousel.Item key={index}>
                            <div className="d-flex justify-content-center">
                                {group.map((item, idx) => (
                                    <div className="gallery-block" key={idx}>
                                        <div className="galleryImg">
                                            <img
                                                src={`https://webapi.entab.info/api/image?url=${item}`}
                                                className="d-block w-100"
                                                alt={item.title || "Image"}
                                            />
                                        </div> 
                                    </div>
                                ))}
                            </div>
                        </Carousel.Item>
                    ))
                ) : (
                    <Carousel.Item>
                        <div className="d-flex justify-content-center">
                            <div className="gallery-block">
                                <div className="galleryImg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" alt="Alternate Text" className="d-block w-100" />
                                </div>
                                <div className="galleryContent">
                                    <h3>Activity Title</h3>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                )}
            </Carousel>
        </>
    );
};

export default KioskActivities;
