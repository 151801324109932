import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from '../Service/Api'; 

const ManagerMessage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        console.log("Fetched Data:", principalData); // Log the fetched data
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
        setError("Failed to fetch data."); // Set error state
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const emptyData = [
    {
      id: 1,
      message: "Welcome to St. Joseph’s High, Mandi Dabwali, a community of learners dedicated to academic excellence, personal growth, and social responsibility. Education is one of the blessings for students. It is the most powerful weapon which can change the entire world. It makes the person powerful and confident. As the manager of this esteemed institution, I am proud to lead a team of passionate educators and support staff who strive to provide a nurturing and inclusive environment for our students. Our mission is to empower and inspire students to become visionary, creative thinkers, equipped with the skills and knowledge to succeed in an ever-changing world.",
      name: "Fr. John Britto",
      category: "Manager",
      attachments:""
    }
  ];

 

  const filteredData = data.filter(div => div.category === "Manager");

  return (
    <>
      {(filteredData.length > 0 ? filteredData : emptyData).map((item, index) => (
        <div className="message-container" key={item.id || index}>
          <div className="messagebox msgright"> 
            <div className="messageimg">  
              <div className="testimonialimg">
                  
                <img
  src={item.attachments ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/manager.jpg"}
  alt="Message"
  className="img-fluid"
/>
              </div>
              <div className="mainbtn button">
                <Link to="/ManagerMessage">READ MORE</Link> 
              </div> 
            </div>
            <div className="messagedesc">
              <div className="maintitle">
                <h3>{item.category}'s Message</h3>
              </div>
              <div className="msginfo">
                <div dangerouslySetInnerHTML={{ __html: item.message }} />
              </div>
            </div>
            <div className="messageboximg">
              <p>{item.name} <span>{item.category}</span></p>
            </div>
          </div>   
        </div>
      ))}
    </>
  );
};

export default ManagerMessage;
