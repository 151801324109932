import React, { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import { Link } from 'react-router-dom'; 

const NoticeBoard = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCircular();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);
  const emptyArray = [
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" }
  ];
 
  return (
    <>
    <div className="newsbg"> 
    <div className='col-md-12 section-title'>
          <div class="maintitle"><div><h1>school  </h1> <p>  Circular  </p> </div><a href="/Circular"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/btn.png" alt="SJMH" /> </a><div class="clr"></div></div>
          
       </div>
    <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up" onMouseOut={(event) => event.currentTarget.start()}>
      <ul className="newsboxlist">
      {newsData.length > 0 ?  newsData?.filter((item) =>  item.category === "Circular").map((item, index) => (   
         <li key={index}>
            <div className="newsbox">
              <div className="newsdesc">
                
                <p>{item.title}</p>
              </div>
              {item.attachments && item.attachments[0] && ( 
                <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link> 
              )} 
            </div>
          </li>
          )) : 
      emptyArray.map((data, index) => (
         <li key={index}>
            <div className="newsbox">
              <div className="newsdesc">
               
              <p>{data.description}</p>
              </div>
              <Link to="#" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/download.png" className="img-fluid"/></Link>
            </div>
          </li>
          ))
        }
      </ul>
    </marquee>
  </div>
    </>
  );
};

export default NoticeBoard;
