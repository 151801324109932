import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from '../Service/Api'; 

const ManagerMessage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        console.log("Fetched Data:", principalData); // Log the fetched data
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
        setError("Failed to fetch data."); // Set error state
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const emptyData = [
    {
      id: 1,
      message: " Welcome to the website of St. Joseph’s High School, Mandi Dabwali. As you navigate your way through the site discovering what we have to offer, you will find answers to the many preliminary questions you may have about our school, our academic programme and student life. As a principal of the school, I feel honored and privileged to be part of an educational institution where every stakeholder is a learner and every day is an opportunity to learn and discover. We look at ourselves as a community of learners, where everyone learns including our teachers, parents and staff.",
      name: "Fr.jerry paul lobo",
      category: "Principal",
      attachments:""
    }
  ];
 
 

  const filteredData = data.filter(div => div.category === "Principal");

  return (
    <>
      {(filteredData.length > 0 ? filteredData : emptyData).map((item, index) => (
        <div className="message-container" key={item.id || index}>
          <div className="messagebox msgleft"> 
            <div className="messageimg">  
              <div className="testimonialimg">
                  
                <img
  src={item.attachments ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/principal.jpg"}
  alt="Message"
  className="img-fluid"
/>
              </div>
              <div className="mainbtn button">
                <Link to="/PrincipalMessage">READ MORE</Link> 
              </div> 
            </div>
            <div className="messagedesc">
              <div className="maintitle">
                <h3>{item.category}'s Message</h3>
              </div>
              <div className="msginfo">
                <div dangerouslySetInnerHTML={{ __html: item.message }} />
              </div>
            </div>
            <div className="messageboximg">
              <p>{item.name} <span>{item.category}</span></p>
            </div>
          </div>   
        </div>
      ))}
    </>
  );
};

export default ManagerMessage;
